import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Aone.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';

import AonePhoto5 from "../../../../res/Photos site/A-one/A-ONE-portrait.jpg";
import AonePhoto6 from "../../../../res/Photos site/A-one/Kool_Koor,_Aone___Toxic_-_September_1982._Photograph_©_Mark_Baron..jpg";
import AonePhoto7 from "../../../../res/Photos site/A-one/Photo 7.jpg";
import AonePhoto8 from "../../../../res/Photos site/A-one/Photo 8.png";
import CarrouselCartel from "../../../../components/CarrouselCartel";

import Aone1 from "../../../../res/Aone/exposition/aone1.jpg"
import Aone2 from "../../../../res/Aone/exposition/aone2.jpg"
import Aone3 from "../../../../res/Aone/exposition/aone3.jpg"
import AonePdp from "./../../../../res/Aone/portrait.jpg";
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/Aone/past-present-1.jpg"
import PastPresent2 from "../../../../res/Aone/past-present-2.jpg"
import PastPresent3 from "../../../../res/Aone/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "aone",
  name: "Aone",
  description: "A-One est le blaze d’Anthony Clark, un artiste graffeur américain légendaire. Né en 1964 à New York, il a commencé à taguer dans les wagons de métro au milieu des années 1970. Très fidèle à ses racines, il s’inspire de la culture afro-américaine contemporaine ainsi que par son ami Jean-Michel Basquiat. A-One s'est fait connaître sur les scènes du graffiti mais aussi de l'art contemporain prônant l’unité des peuples et la tolérance à travers la mythologie urbaine, son fils conducteur a aussi été la recherche identitaire. Il a notamment développé une esthétique plus libre que ses compatriotes de Manhattan tels que Daze et a rejoint un groupe d'artistes connu sous le nom de \" Tag Master Killers \" qui comprenait Rammellzee, Toxic et Delta 2. En 1982, il a participé à une exposition de graffitis qui a fait date dans le South Bronx et a ensuite été inclus dans la Biennale de Venise de 1984, dont il était le plus jeune participant. Plus tard dans sa vie, il s'est installé à Paris, où il a continué à travailler jusqu'à sa mort d'une hémorragie cérébrale inattendue le 11 novembre 2001 à l’âge de 37 ans seulement.",
  citations: [
    "« Good Bye My Hit master your work is done only on this mold of a planet ! You always Drew a Great Ikonoklast Panzerism letter Tank for me… Fly in your \"A\" far and Hit to hurt as you always have... Like my #1...We all know you will \"ROCK THE GALAXY\" now that you've got the chance... So Burn'em! I will be watching »"
  ],
  pdp: AonePhoto5,
  alt_pdp: "Photo de profil de Aone.",
  photos: [
    { src: PastPresent1, name: "Aone" },
    { src: PastPresent2, name: "Aone" },
    { src: PastPresent3, name: "Aone" },
  ],
};

const infos2 = {
  photos: [
    { src: Aone1, name: "A-One", artist: "A-One\n(Anthony Clarke dit) (1964 - 2001)", title:"The Spectator & the Zimba, 1996", desc1:"Huile et Acrylique sur toile", desc2:"Oil and acrylic on canvas", desc3:"110 x 110 cm", desc4:"43 1⁄4 x 43 1⁄4 in.", desc5:"Signée, titrée, datée et dédicacée au dos.", desc6:"Signed, titled, dated and dedicated on the reverse", desc7: "- Collection particulière", desc8 : "<h4><i>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.\n- A-One, exposition rétrospective, Ghost galerie, Marseille, France. 22 Juin - 27 Juillet, 2019.</i></h4>", desc9:"<h4><i>- A-One</i>, exposition rétrospective, Ghost galerie, Marseille, France, 22 Juin - 27 Juillet 2019, reproduit dans le catalogue p.71.</h4>"  },
    { src: Aone2, name: "A-One", artist: "A-One\n(Anthony Clarke dit) (1964 - 2001)", title:"Untangeled, 1991", desc1:"Peinture aérosol et acrylique sur toile", desc2:"Spray paint and acrylic on canvas", desc3:"120 x 249 cm", desc4:"47 1⁄4 x 98 1⁄2 in.", desc5:"Signée, titrée, et datée au dos : \" Untangeled, 1991\"", desc6:"Signed, titled and dated on the reverse : \" Untageled, 1991 \"", desc7: "- Collection particulière, France.", desc8 :"<h4>- <i>A-One</i>, exposition rétrospective, Ghost galerie, Marseille, France. 22 Juin - 27 Juillet, 2019 (catalogue).</h4>", desc9:"<h4>-<i>A-One</i>, exposition rétrospective a cura di Sharp, Ghost galerie, Marseille, France, 22 Juin - 27 Juillet 2019, reproduit en pleine page dans le catalogue en couverture et p. 26-27.</h4>" },
    { src: Aone3, name: "A-One", artist: "A-One\n(Anthony Clarke dit) (1964 - 2001)", title:"Sans titre, 1986", desc1:"Encre sur papier", desc2:"Ink on paper", desc3:"44,4 x 56 cm avec cadre\n35,5 x 47 cm sans cadre", desc4:"17 1⁄2 x 22 1⁄8 in. (framed)\n14 x 18 1/2 in.(unframed)", desc5:"Signé sur le devant", desc6:"Signed on the front", desc7: "- Collection particulière\n- Colection personnelle de John Matos (Crash).", desc8 :"<h4>- <i>A-One</i>, exposition rétrospective, Ghost galerie, Marseille, France. 22 Juin - 27 Juillet, 2019 (catalogue).</h4>", desc9:"<h4>-<i>A-One</i>, exposition rétrospective a cura di Sharp, Ghost galerie, Marseille, France, 22 Juin - 27 Juillet 2019, reproduit dans le catalogue p. 34-35.</h4>" },
  ],
};

const exhibitions = [
  {
    date: "Juin-Juillet 2019",
    title: "AONE EXPO",
    link: "aone",
    artist: "",
    place: "2 rue de Belloi - 13006 Marseille",
    description: "Plus de 40 oeuvres présentées (toiles et dessins)",
    presentation_photo: AonePdp,
  },
]

const Aone = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
   <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>AONE <h2 style={{paddingLeft: "16px"}}>(Anthony Eden Clark - Américain)</h2></h1>
          <h3 className={NameWrapper} >1964 - 2001</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <a href={"/fr/expositions/" + exhibitions[0].link} className={LinkWrapper}>« AONE RETROSPECTIVE » - du 22 Juin au 27 juillet 2019 - Marseille.</a>
       <ul  className={ArtistInfos}>
       <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- A-One, exposition rétrospective / retrospective exhibition, Ghost galerie, Marseille, France, June 22th - July 27th (catalogue).</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- A-One (Ikonoklast Panzerism), Galleria Salvatore + Caroline Ala. Milan, Italy, July 1st - October 9th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- A-One - Dark and Wild; 1989-1990, Graffiti, Street- Art, Galerie Quintessens. Utrecht, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Piccolo Museum, Lecce, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Galleria Salvatore Ala, Milan, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Hamilton Place, Installation, New York, USA.</p>
          <br />
          <div className={More} id="more">
          <br />
            <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
            <br />
            <p style={{fontWeight: "bold"}}>2022</p>
            <p>- New York Street Style : création des années 80, Ghost Galerie, Paris, juillet - septembre 2022.</p>
            <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
            <br />
            <p style={{fontWeight: "bold"}}>2021</p>
            <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
            <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</p>
            <p>- Writing the future, Basquiat and the Hip-Hop Generation, Museum of fine arts, Boston, USA, October 18th - July 25th 2021.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2012</p>
            <p>- Graffiti - New York 80's, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2009</p>
            <p>- Né dans la rue - Graffiti, Fondation Cartier pour l’Art Contemporain. Paris, France, Juillet 7th, 2009 - Janvier 10th, 2010 (catalogue).</p>
            <br />
            <p style={{fontWeight: "bold"}}>2007</p>
            <p>- Il Futuro del Futurismo, GAMeC - Galleria d ́Arte Moderna e Contemporanea di Bergamo, Italy, September 21st, 2007 - February 2nd, 2008 (catalogue).</p>
            <br />
            <p style={{fontWeight: "bold"}}>2006</p>
            <p>- Graffiti, The Brooklyn Museum. New York, USA, June 30th - September 3rd.</p>
            <p>- Gezeichnet Graffiti, Kunsthalle Darmstadt, Darmstadt, Germany, April 4th - May 31st (catalogue).</p>
            <br />
            <p style={{fontWeight: "bold"}}>2001</p>
            <p>- Graffiti, Galerie du jour Agnès B, Paris, France. </p>
            <br />
            <p style={{fontWeight: "bold"}}>1994</p>
            <p>- Futura 2000, Time Space Light. New York, USA.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1992</p>
            <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th - January 10th. (catalogue)</p>
            <br />
            <p style={{fontWeight: "bold"}}>1991</p>
            <p>- American Graffiti : A Survey, Liverpool Gallery, Brussels, Belgium.</p>
            <p>- Post Graffiti / Fine Art, Federal Reserve Board Building, Washington D.C., USA.</p>
            <p>- Graffiti Art : Artiste américains et français 1981/ 1991 (Collection Speerstra), Musée des monuments Français Paris. (catalogue)</p>
            <br />
            <p style={{fontWeight: "bold"}}>1990</p>
            <p>- Post Graffiti, Figuration Libre, Fondation Veranneman, Kruishoutem, Belgium. (catalogue) </p>
            <br />
            <p style={{fontWeight: "bold"}}>1988</p>
            <p>- New York Graffiti, Sammlung Ludwig, Aachen; Hans Metternich, Koblenz, Germany.</p>
            <br />
          <p style={{fontWeight: "bold"}}>1987</p>
            <p>- Beyond Words (Science & Symbiotics), Fashion Moda, Bronx, USA.</p>
            <p>- Ludwig Collection, Fort Belvedere, Florence, Italy.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1986</p>
            <p>- A&P Gallery, Mission Gallery, New York, USA.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1985</p>
            <p>- 3 Graffiti Artists - A One, Noc & Toxic, Sidney Janis Gallery, New York, USA. March 14th - April 6th. (catalogue)</p>
            <p>- Zeitgenössische Kunst, Galerie Thomas, Munich, Germany. (catalogue) </p>
            <br />
            <p style={{fontWeight: "bold"}}>1984</p>
            <p>- Graffiti, Maker Gallery, Philadelphia, USA.</p>
            <p>- Arte di Frontiera, Galleria Comunale d’Arte Moderna, Bologne, Italy. (catalogue)</p>
            <p>- Classical American Graffiti Writers and High Graffiti Artist, Galerie Thomas, Munich, Germany.</p>
            <p>- Graffiti, Maurice Keitelman Gallery, Brussels, Belgium.</p>
            <p>- Robert Fraser Gallery, London, UK.</p>
            <p>- Arte e Arti. Attualità e Storia, 41st Venice Biennale, Venice, Italy, June 10th - September 9th (catalogue).</p>
            <p>- Rapid Enamel The Art of Graffiti, The Renaissance Society at The University of Chicago, curated by Richard Flood. Chicago, USA, October 7th - November 10th (catalogue).</p>
            <br />
            <p style={{fontWeight: "bold"}}>1983</p>
            <p>- Post-Graffiti, Sidney Janis Gallery. New York, USA, December 1st - 31st. (catalogue)</p>
            <p>- Jenny Holzer: Survival Series with A-One, Mike Glier & Lady Pink, Lisson Gallery, London, UK, 12th May – 4th June 1983.</p>
            <p>- Camouflaged Panzerism ptl, Fashion Moda. New York, USA, December 11th - January 8th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1982</p>
            <p>- Studio 54 Installation, New York, USA.</p>
            <p>- South Bronx Show, Fashion Moda. New York, USA, June 26th - August 27th.</p>
            <br />
          <p style={{fontWeight: "bold"}}>1980</p>
            <p>- GAS: Graffiti Art Success for America, Fashion Moda, curated by John Matos (aka Crash). New York, USA, October 18th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1975</p>
            <p>- The Cleveland Museum of Art, Cleveland, USA.</p>
            <br />
            <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
            <br />
            <p>- Frederick R. Weisman Foundation, Los Angeles, USA.</p>
            <p>- Dr. Peter Ludwig, Aachen, Germany.</p>
            <p>- Mats Pousette, Stockholm, Sweden.</p>
            <p>- Professor Peter Iden, Frankfurt, Germany.</p>
            <p>- Mark Twain South County Bank, St. Louis, USA.</p>
            <p>- Josie Landau, New York, USA.</p>
            <p>- Claudio Bruni, Rome, Italy.</p>
            <br />
            <p style={{fontWeight: "bolder"}}>LITERATURE</p>
            <br />
            <p>- A-One, exposition rétrospective - retrospective exhibition, Ghost galerie, Marseille, France, Juin 22nd - Juillet 27th, 2019 (catalogue).</p>
            <p>- Né dans la rue - Graffiti, Fondation Cartier pour l’Art Contemporain. Paris, France, Juillet 7th, 2009 - Janvier 10th, 2010 (catalogue).</p>
            <p>- Il Futuro del Futurismo, GAMeC - Galleria d ́Arte Moderna e Contemporanea di Bergamo, Italy, September 21, 2007 - February 2nd, 2008 (catalogue).</p>
            <p>- Gezeichnet Graffiti, Kunsthalle Darmstadt, Darmstadt, Germany, April 4th - May 31st, 2004 (catalogue).</p>
            <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th - January 10th, 1992 (catalogue).</p>
            <p>- Graffiti Art : Artiste américains et français 1981/ 1991 (Collection Speerstra), Musée des monuments Français Paris, 1991 (catalogue).</p>
            <p>- Post Graffiti, Figuration Libre, Fondation Veranneman, Kruishoutem, Belgium, 1990 (catalogue).</p>
            <p>- 3 Graffiti Artists - A One, Noc & Toxic, Sidney Janis Gallery, New York, USA. March 14th - April 6th, 1985 (catalogue).</p>
            <p>- Zeitgenössische Kunst, Galerie Thomas, Munich, Germany, 1985 (catalogue).</p>
            <p>- Arte e Arti. Attualità e Storia, 41st Venice Biennale, Venice, Italy, June 10th - September 9th, 1984 (catalogue).</p>
            <p>- Rapid Enamel The Art of Graffiti, The Renaissance Society at The University of Chicago,</p>
            <p>curated by Richard Flood. Chicago, USA, October 7th - November 10th, 1984 (catalogue).</p>
            <p>- 3 Graffiti Artists - A One, Noc & Toxic, Sidney Janis Gallery, New York, USA. March 14th - April 6th, 1984 (catalogue).</p>
            <p id="end">- Arte di Frontiera, Galleria Comunale d’Arte Moderna, Bologne, Italy, 1984.</p>
          </div>
        </ul>
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
      </div>
      </div>

      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Aone;
// extracted by mini-css-extract-plugin
export var ArtistDescription = "Aone-module--ArtistDescription--239mU";
export var ArtistInfos = "Aone-module--ArtistInfos--uZxnQ";
export var ButtonWrapper = "Aone-module--ButtonWrapper --+kKf2";
export var CardWrapper = "Aone-module--CardWrapper--tfXda";
export var CarrouselWrapper2 = "Aone-module--CarrouselWrapper2--31ggC";
export var Citations = "Aone-module--Citations--5QwrC";
export var DescriptionWrapper = "Aone-module--DescriptionWrapper--KUOv1";
export var ImageWrapper = "Aone-module--ImageWrapper--VPT96";
export var LinkWrapper = "Aone-module--LinkWrapper--pnEdX";
export var MobileProtrait = "Aone-module--MobileProtrait--Kn2Bv";
export var More = "Aone-module--More--CObGP";
export var MoreButton = "Aone-module--MoreButton--TAppd";
export var NameWrapper = "Aone-module--NameWrapper--j4D29";
export var PdpWrapper = "Aone-module--PdpWrapper--gEVFa";
export var PhotosWrapper = "Aone-module--PhotosWrapper--MMKoR";
export var ProfilWrapper = "Aone-module--ProfilWrapper--tg-Aw";
export var TitleWrapper = "Aone-module--TitleWrapper--E6y4H";
export var Wrapper = "Aone-module--Wrapper--xracY";
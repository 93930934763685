import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import Aone from "./../../../nav/fr/Artistes/Aone";
import { Helmet } from "react-helmet";

const AonePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AONE | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,anthonyclarkaone,aonegraffiti,aoneartist,aoneartiste,anthonyclark,bronxartiste,bronxartist,newyorkartist,americanart,aonespraypaint,aoneaerosol,aonepating,aoneavailable,aonedrawing,aonecolorlife,aonebook,aoneretrospectiveexhibition,retrospectiveexhibition,aonegallery,aonegalerie,aoneghostgalerie,aonesharp,aonetoxic,aonebasquiat,aoneagnesB,agnesb,aoneexposition,aoneexhibition,aone,artstudio,artgalleryparis,galerieartparis,artgallerymarseille,galerieartmarseille,artcontemporaingallery,artcontemporaingalerie" />
      </Helmet>
      <Aone />
    </Layout>
  );
};

export default AonePage;
